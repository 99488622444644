<template>
  <section class="min-w1600">
    <div class="searchwrap">
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('searchArea.date') }}</span></div>
          <div class="fx">
            <date-selector :class="'searchbar-content'" @setStartDate="setStartDate" @setEndDate="setEndDate" :startDefault="startDefault"/>
            <button class="btn-search ml-5" type="button" @click="loadList(selectMem.memId)">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="searchBar">
        <div class="box-ui-select searchbar-content">
          <div class="title"><span>{{ $t('table.head.memType') }}</span></div>
          <select class="reqMember" v-model="reqData.userType">
            <option value="">{{ $t('common.entire') }}</option>
            <option value="N">{{ $t('searchArea.user') }}</option>
            <option value="P">{{ $t('searchArea.partner') }}</option>
          </select>
        </div>
        <div class="searchbar-content">
          <div class="title"><span>{{ $t('searchArea.id') }}</span></div>
          <div>
            <input type="text" class="mr-5"/>
            <button class="btn-search mr-5" type="button"> <!--@click="pageSeach(1)"-->
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      <memo></memo>
    </div>
  </section>
  <section class="min-w1600">
    <div class="left box">
      <h2>
        {{ $t('common.partnerList') }}
        <div class="toggle-item">
          <span class="toggle-title">{{ $t('table.head.userDirect') }}</span>
          <input type="checkbox" class="set-switch" v-model="isTreeOption" />
        </div>
      </h2>
      <ul class="list">
        <template v-for="(item1) in partnerList" :key="item1.memId">
          <li :class="[item1.partnerLevel, item1.cnt ? 'morebtn': '', {'moreOn':item1.children && item1.children.length > 0}]">
            <template v-if="item1.cnt">
               <span @click="listOpen(item1)" :class="{'moreOn':item1.children && item1.children.length > 0}"></span>
            </template>
            <template v-else>
               <span @click="listOpen(item1)" class="none"></span>
            </template>
            <div @click="loadPartnerList(item1)">
              <em>{{item1.partnerLevelName}}</em>
              <div :class="{ 'active': selectedLi === item1.memId }">
                 {{item1.memId}}
                 <span class="nick">{{item1.memNick}}</span>
                 <!-- <template v-if="item1.cnt">
                   ({{item1.cnt}})
                 </template> -->
              </div>
            </div>
            <ul class="more" v-if="item1.children && item1.children.length > 0">
              <template v-for="(item2) in item1.children" :key="item2.memId">
                <li :class="[item2.partnerLevel, item2.cnt ? 'morebtn': '']">
                  <template v-if="item2.cnt">
                     <span @click="listOpen(item2)" :class="{'moreOn':item2.children && item2.children.length > 0}"></span>
                  </template>
                  <template v-else>
                     <span @click="listOpen(item2)" class="none"></span>
                  </template>
                  <div @click="loadPartnerList(item2)">
                    <em>{{item2.partnerLevelName}}</em>
                    <div :class="{ 'active': selectedLi === item2.memId }">
                       {{item2.memId}}
                       <span class="nick">{{item2.memNick}}</span>
                       <!-- <template v-if="item2.cnt">
                         ({{item2.cnt}})
                       </template> -->
                    </div>
                  </div>
                  <ul class="more" v-if="item2.children && item2.children.length > 0">
                    <template v-for="(item3) in item2.children" :key="item3.memId">
                      <li :class="[item3.partnerLevel, item3.cnt ? 'morebtn': '']">
                        <template v-if="item3.cnt">
                           <span @click="listOpen(item3)" :class="{'moreOn':item3.children && item3.children.length > 0}"></span>
                        </template>
                        <template v-else>
                           <span @click="listOpen(item3)" class="none"></span>
                        </template>
                        <div @click="loadPartnerList(item3)">
                          <em>{{item3.partnerLevelName}}</em>
                          <div :class="{ 'active': selectedLi === item3.memId }">
                             {{item3.memId}}
                             <span class="nick">{{item3.memNick}}</span>
                             <!-- <template v-if="item3.cnt">
                               ({{item3.cnt}})
                             </template> -->
                          </div>
                        </div>
                        <ul class="more" v-if="item3.children && item3.children.length > 0">
                          <template v-for="(item4) in item3.children" :key="item4.memId">
                            <li :class="[item4.partnerLevel, item4.cnt ? 'morebtn': '']">
                              <template v-if="item4.cnt">
                                 <span @click="listOpen(item4)" :class="{'moreOn':item4.children && item4.children.length > 0}"></span>
                              </template>
                              <template v-else>
                                 <span @click="listOpen(item4)" class="none"></span>
                              </template>
                              <div @click="loadPartnerList(item4)">
                                <em>{{ item4.partnerLevelName }}</em>
                                <div :class="{ 'active': selectedLi === item4.memId }">
                                   {{item4.memId}}
                                   <span class="nick">{{item4.memNick}}</span>
                                   <!-- <template v-if="item4.cnt">
                                     ({{item4.cnt}})
                                   </template> -->
                                </div>
                              </div>
                            </li>
                            <ul class="more" v-if="item4.children && item4.children.length > 0">
                              <template v-for="(item5) in item4.children" :key="item5.memId">
                                <li :class="[item5.partnerLevel, item5.cnt ? 'morebtn': '']">
                                  <div @click="loadPartnerList(item5)">
                                    <em>{{ item5.partnerLevelName }}</em>
                                    <div :class="{ 'active': selectedLi === item5.memId }">
                                       {{item5.memId}}
                                       <span class="nick">{{item5.memNick}}</span>
                                       <!-- <template v-if="item5.cnt">
                                         ({{item5.cnt}})
                                       </template> -->
                                    </div>
                                  </div>
                                </li>
                              </template>
                            </ul>
                          </template>
                        </ul>

                      </li>
                    </template>
                  </ul>
                </li>
              </template>
            </ul>
          </li>
        </template>
      </ul>
    </div>
    <div class="right box">
      <!-- <h2>
        날짜별 정산 리스트
        <ul class="headinfo" v-if="selectMem && partnerInfo">
          <li class="info">
            <em>{{selectMem ? selectMem.partnerLevelName : ''}}</em>
            {{selectMem ? selectMem.memId : ''}}
            <span>{{selectMem ? selectMem.memNick : ''}}</span>
          </li>
          <li class="rate">
            <em>카지노</em>
            <span>{{partnerInfo.casinoRate}}%</span>
          </li>
          <li class="rate">
            <em>슬롯</em>
            <span>{{partnerInfo.slotRate}}%</span>
          </li>
          <li class="rate">
            <em>스포츠</em>
            <span>{{partnerInfo.sportRate}}%</span>
          </li>
          <li class="rate">
            <em>미니게임</em>
            <span>{{partnerInfo.miniRate}}%</span>
          </li>
        </ul>
      </h2> -->
      <div>
        <div class="table-top">
          <ul class="tab-wrap">
            <li @click="currentTab = 'casino'" :class="{'active': currentTab === 'casino'}" class="tab-item">{{ $t('table.head.casino') }}</li>
            <li @click="currentTab = 'slot'" :class="{'active': currentTab === 'slot'}" class="tab-item">{{ $t('table.head.slot') }}</li>
            <li @click="currentTab = 'sport'" :class="{'active': currentTab === 'sport'}" class="tab-item">{{ $t('table.head.sport') }}</li>
            <li @click="currentTab = 'mini'" :class="{'active': currentTab === 'mini'}" class="tab-item">{{ $t('table.head.mini') }}</li>
          </ul>
          <div class="tab-head" v-if="selectMem && partnerInfo">
            <p class="tab-user">
               <em :class="selectMem.partnerLevel" class="boxs">{{selectMem ? selectMem.partnerLevelName : ''}}</em>
               <span class="head-name">{{selectMem ? selectMem.memId : ''}}</span>
               <span class="head-nick">{{selectMem ? selectMem.memNick : ''}}</span>
            </p>
            <ul class="tab-rate">
              <li>
                <span class="gametype casino">{{ $t('table.head.casino') }}</span>
                <span class="gamerate">{{partnerInfo.casinoRate}}%</span>
              </li>
              <li>
                <span class="gametype slot">{{ $t('table.head.slot') }}</span>
                <span class="gamerate">{{partnerInfo.slotRate}}%</span>
              </li>
              <li>
                <span class="gametype sport">{{ $t('table.head.sport') }}</span>
                <span class="gamerate">{{partnerInfo.sportRate}}%</span>
              </li>
              <li>
                <span class="gametype mini">{{ $t('table.head.mini') }}</span>
                <span class="gamerate">{{partnerInfo.miniRate}}%</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="table-wrapper">
          <table class="mainTable">
            <colgroup>
              <col style="width:150px;">
              <col style="width:11.1%;">
              <col style="width:11.1%;">
              <col style="width:11.1%;">
              <col style="width:11.1%;">
              <col style="width:11.1%;">
              <col style="width:11.1%;">
              <col style="width:11.1%;">
            </colgroup>
            <thead>
              <tr>
                <th>{{ $t('searchArea.date') }}</th>
                <th>{{ $t('table.head.deposit') }}</th>
                <th>{{ $t('table.head.withdraw') }}</th>
                <th>{{ $t('table.head.deWith') }}</th>
                <th>{{ $t('table.head.cashAmt') }}</th>
                <th>{{ $t('betting.totalBetAmt') }}</th>
                <th>{{ $t('betting.totalwinAmt') }}</th>
                <th>{{ $t('betting.totalRollAmt') }}</th>
                <th>
                  {{ $t('betting.totalrevenAmt') }}
                   <div class="rollchk">
                      <!--label for="isBS">롤링금 제외 계산</label>
                      <input type="checkbox" id="isBS" :checked="isBS" @setYn="onChangeBS" /-->
                      <ui-check :id="'isBS'" :text="$t('betting.rollCal')" :class="'ui-check'" :checked="isBS" @setYn="onChangeBS"/>
                   </div>
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="dailyList.length > 0">
                 <tr class="total">
                   <td class="rightline">{{ $t('table.head.summary') }}</td>
                   <td class="inout roboto">{{ thousand(dailyTotal ? dailyTotal.cashIn : 0) }}</td>
                   <td class="inout roboto">{{ thousand(dailyTotal ? dailyTotal.cashOut : 0) }}</td>
                   <td class="inout roboto">{{ thousand(dailyTotal ? dailyTotal.cashResult : 0) }}</td>
                   <td class="inout roboto rightline">{{ thousand(dailyTotal ? dailyTotal.cashAmt : 0) }}</td>
                   <td>
                     <span v-if="currentTab === 'casino'" class="roboto">{{thousand(dailyTotal ? dailyTotal.casinoBetAmt : 0) }}</span>
                     <span v-if="currentTab === 'slot'" class="roboto">{{thousand(dailyTotal ? dailyTotal.slotBetAmt : 0) }}</span>
                     <span v-if="currentTab === 'sport'" class="roboto">{{thousand(dailyTotal ? dailyTotal.sportBetAmt : 0) }}</span>
                     <span v-if="currentTab === 'mini'" class="roboto">{{thousand(dailyTotal ? dailyTotal.miniBetAmt : 0) }}</span>
                   </td>
                   <td>
                     <span v-if="currentTab === 'casino'" class="roboto">{{thousand(dailyTotal ? dailyTotal.casinoWinAmt : 0) }}</span>
                     <span v-if="currentTab === 'slot'" class="roboto">{{thousand(dailyTotal ? dailyTotal.slotWinAmt : 0) }}</span>
                     <span v-if="currentTab === 'sport'" class="roboto">{{thousand(dailyTotal ? dailyTotal.sportWinAmt : 0) }}</span>
                     <span v-if="currentTab === 'mini'" class="roboto">{{thousand(dailyTotal ? dailyTotal.miniWinAmt : 0) }}</span>
                   </td>
                   <td>
                     <span v-if="currentTab === 'casino'" class="roboto">{{thousand(dailyTotal ? dailyTotal.casinoRolling : 0) }}</span>
                     <span v-if="currentTab === 'slot'" class="roboto">{{thousand(dailyTotal ? dailyTotal.slotRolling : 0) }}</span>
                     <span v-if="currentTab === 'sport'" class="roboto">{{thousand(dailyTotal ? dailyTotal.sportRolling : 0) }}</span>
                     <span v-if="currentTab === 'mini'" class="roboto">{{thousand(dailyTotal ? dailyTotal.miniRolling : 0) }}</span>
                   </td>
                   <td>
                     <span v-if="currentTab === 'casino'" class="roboto">{{thousand(!isBS ? dailyTotal.casinoResult : dailyTotal.casinoResult - dailyTotal.casinoRolling) }}</span>
                     <span v-if="currentTab === 'slot'" class="roboto">{{thousand(!isBS ? dailyTotal.slotResult : dailyTotal.slotResult - dailyTotal.slotRolling) }}</span>
                     <span v-if="currentTab === 'sport'" class="roboto">{{thousand(!isBS ? dailyTotal.sportResult : dailyTotal.sportResult - dailyTotal.sportRolling) }}</span>
                     <span v-if="currentTab === 'mini'" class="roboto">{{thousand(!isBS ? dailyTotal.miniResult : dailyTotal.miniResult - dailyTotal.miniRolling) }}</span>
                   </td>
                 </tr>
                <template v-for="item in dailyList">
                  <tr>
                    <td class="roboto rightline">{{item.days}} ({{item.dayLabel}})</td>
                    <td class="inout">
                      <span class="roboto">{{thousand(item.cashIn)}}</span>
                    </td>
                    <td class="inout">
                      <span class="roboto">{{thousand(item.cashOut)}}</span>
                    </td>
                    <td class="inout">
                      <span class="roboto">{{thousand(item.cashResult)}}</span>
                    </td>
                    <td class="inout rightline">
                      <span class="roboto">{{thousand(item.cashAmt)}}</span>
                    </td>
                    <td>
                      <span v-if="currentTab === 'casino'" class="roboto">{{thousand(item.casinoBetAmt)}}</span>
                      <span v-if="currentTab === 'slot'" class="roboto">{{thousand(item.slotBetAmt)}}</span>
                      <span v-if="currentTab === 'sport'" class="roboto">{{thousand(item.sportBetAmt)}}</span>
                      <span v-if="currentTab === 'mini'" class="roboto">{{thousand(item.miniBetAmt)}}</span>
                    </td>
                    <td>
                      <span v-if="currentTab === 'casino'" class="roboto">{{thousand(item.casinoWinAmt)}}</span>
                      <span v-if="currentTab === 'slot'" class="roboto">{{thousand(item.slotWinAmt)}}</span>
                      <span v-if="currentTab === 'sport'" class="roboto">{{thousand(item.sportWinAmt)}}</span>
                      <span v-if="currentTab === 'mini'" class="roboto">{{thousand(item.miniWinAmt)}}</span>
                    </td>
                    <td>
                      <span v-if="currentTab === 'casino'" class="roboto">{{thousand(item.casinoRolling)}}</span>
                      <span v-if="currentTab === 'slot'" class="roboto">{{thousand(item.slotRolling)}}</span>
                      <span v-if="currentTab === 'sport'" class="roboto">{{thousand(item.sportRolling)}}</span>
                      <span v-if="currentTab === 'mini'" class="roboto">{{thousand(item.miniRolling)}}</span>
                    </td>
                    <td>
                      <span v-if="currentTab === 'casino'" class="roboto">{{thousand(!isBS ? item.casinoResult : item.casinoResult - item.casinoRolling)}}</span>
                      <span v-if="currentTab === 'slot'" class="roboto">{{thousand(!isBS ? item.slotResult : item.slotResult - item.slotRolling)}}</span>
                      <span v-if="currentTab === 'sport'" class="roboto">{{thousand(!isBS ? item.sportResult : item.sportResult - item.sportRolling)}}</span>
                      <span v-if="currentTab === 'mini'" class="roboto">{{thousand(!isBS ? item.miniResult : item.miniResult - item.miniRolling)}}</span>
                    </td>
                  </tr>
                </template>
              </template>
              <template v-else>
              </template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import DateSelector from '@/components/common/DateSelector'
import UiCheck from '@/components/ui/UiCheck'
import { addDays } from 'date-fns'
import { getDateStr, thousand } from '@/libs/utils'
import { dailyCalculate, dailyCalculateForPartner } from '@/api/report'
import { PARTNER_LEVEL } from '@/libs/constants'
import Memo from '@/components/common/memo'

export default {
  name: 'totalList2',
  components: {
    DateSelector,
    UiCheck,
    Memo
  },
  data: function () {
    return {
      currentTab: 'casino',
      isTreeOption: true,
      reqData: {
        startDate: '',
        endDate: '',
        memId: '',
        userType: '',
        treeOption: 'Y'
      },
      reqDataPartner: {
        memId: ''
      },
      partnerList: [],
      startDefault: '',
      endDefault: '',
      partnerInfo: {},
      dailyList: [],
      dailyTotal: {},
      selectMem: null,
      selectedLi: null,
      isBS: false
    }
  },
  computed: {
    computedPartnerLevel () {
      return PARTNER_LEVEL[this.data.partnerLevel]
    }
  },
  watch: {
    isTreeOption () {
      this.reqData.treeOption = this.isTreeOption ? 'Y' : ''
    }
  },
  methods: {
    onChangeBS (value) {
      this.isBS = value === 'Y'
    },
    thousand,
    getTodayLabel (dateStr) {
      const date = new Date(dateStr)

      const week = ['일', '월', '화', '수', '목', '금', '토']
      const getDay = date.getDay()

      return week[getDay]
    },
    listOpen (item) {
      this.selectMem = item
      this.reqDataPartner.memId = item ? item.memId : ''

      if (!item || item.cnt > 0) {
        if (!item || !item.children || item.children.length === 0) {
          dailyCalculateForPartner(this.reqDataPartner).then(res => {
            console.log(res)
            if (res.resultCode === '0') {
              const data = res.data.hierarchy
              data.forEach(item => {
                item.isOpen = false
              })
              if (item) {
                this.selectMem = ''
                this.dailyList = []
                this.partnerInfo = ''
                item.children = data
              } else {
                this.partnerList = data
              }
            }
          })
        } else {
          this.selectMem = ''
          this.dailyList = []
          this.partnerInfo = ''
          delete item.children
        }
      }
    },
    loadPartnerList (item) {
      this.emitter.emit('Loading', true)
      this.selectMem = item
      this.reqDataPartner.memId = item ? item.memId : ''

      if (!item || item.cnt > 0) {
        if (!item || !item.children || item.children.length === 0) {
          dailyCalculateForPartner(this.reqDataPartner).then(res => {
            console.log(res)
            if (res.resultCode === '0') {
              const data = res.data.hierarchy
              data.forEach(item => {
                item.isOpen = false
              })
              if (item) {
                // item.children = data
              } else {
                this.partnerList = data
              }
            }
          })
        }
      }
      if (item) {
        this.loadList(item.memId)
      } else {
        this.emitter.emit('Loading', false)
      }
    },
    loadList (memId) {
      this.emitter.emit('Loading', true)
      this.reqData.memId = memId

      this.partnerInfo = {}
      this.dailyList = []

      if (this.selectedLi === memId) {
        this.selectedLi = memId
      } else {
        this.selectedLi = memId
      }

      dailyCalculate(this.reqData).then(res => {
        if (res.resultCode === '0') {
          this.partnerInfo = res.data.partnerInfo
          this.dailyList = res.data.dailyList

          for (const key in this.dailyTotal) {
            this.dailyTotal[key] = 0
          }

          this.dailyList.forEach(item => {
            item.dayLabel = this.getTodayLabel(item.days)
            for (const key in item) {
              if (typeof item[key] === 'number') {
                if (!this.dailyTotal[key]) {
                  this.dailyTotal[key] = 0
                }
                this.dailyTotal[key] += item[key]
              }
            }
          })

          this.emitter.emit('Loading', false)
          console.log(this.dailyTotal)
          console.log(res)
        }
      })
    },
    setStartDate (date) {
      // console.log(date);
      let _date = ''
      if (!date) {
        const targetDate = new Date()
        targetDate.setDate(1)
        targetDate.setHours(0, 0, 0)
        _date = targetDate
      } else {
        _date = date[0]
      }
      this.startDefault = _date
      this.reqData.startDate = getDateStr(_date, 'yyyy-MM-dd')
    },
    setEndDate (date) {
      console.log('setEndDate : ', date)
      let _date = ''
      if (!date) {
        _date = addDays(new Date(), 0)
      } else {
        _date = date[0]
      }
      this.endDefault = _date
      this.reqData.endDate = getDateStr(_date, 'yyyy-MM-dd')
    }
  },
  created () {
    this.loadPartnerList()
    this.setStartDate()
    this.setEndDate()
  }
}
</script>
<style scoped>
.head-name {color: #585963;}
.head-nick {color: #7ca0d2;}
.rollchk {font-size: 10px;color: #585963;display: flex;align-items: center;justify-content: center;gap: 5px;margin-top: 5px;}
>>> input[type="checkbox"]:checked + label i {border: 1px solid #919296;background: #353535;}
.ui-check-wrap >>> i {border: 1px solid #919296;border-radius: 2px;}
section {
  display: flex;
  justify-content: space-between;
  padding: 0 35px;
  gap: 20px;
}
.box {
   min-height: 600px;
   padding: 20px;
   box-sizing: border-box;
}
.left {
  width: 320px;
  min-width: 320px;
  background-color: #fbfbfb;
  border: solid 1px #b1b1b169;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.right {
  width: calc(100% - 340px);
  padding: 0;
  min-width: 1000px;
}
.right > div {
  height: 100%;
}
.left h2, .right h2 {
   padding: 5px 10px 10px;
   color: #3d3d3d;
   font-size: 14px;
   font-weight: 800;
   text-shadow: 0 1px 2px rgba(0, 0, 0, 0.16);
   display: flex;
   align-items: center;
   justify-content: space-between;
}
.left .toggle-item {flex-direction: row;align-items: center;}
.right h2 {padding: 5px 0 20px 0;}
.headinfo {
  font-weight: normal;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px;
}
.headinfo li {
  align-items: center;
  display: flex;
  gap: 5px;
}
.info{
  margin-right: 5px;
}
.info em{
  background: #052659;
  padding: 3px 5px;
  border-radius: 3px;
  color: #fff;
}
.info span {
  color: #aeb7c4;
}
.headinfo li:nth-child(2) em {
  color: #eb7b3f;
}
.headinfo li:nth-child(3) em {
  color: #82a6a4;
}
.headinfo li:nth-child(4) em {
  color: #806fad;
}
.headinfo li:nth-child(5) em {
  color: #f6ac01;
}
.rate span{
  padding: 3px 5px;
  background:#eee;
  border-radius: 3px;
  color:#5c5e60;
}
.searchwrap {width: 100%;margin: 0;margin-bottom: 24px;border: solid 1px #b1b1b169;}
.table-top {
  border-bottom: 4px solid #ffa354;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.tab-wrap {
  border-bottom: 0;
}
.tablist{
  display: flex;
  margin-top: 20px;
}
.tablist li{
  padding: 12px 17px;
  border-radius: 3px 3px 0 0;
  border: 1px solid #bbb;
  border-right:0;
  box-sizing: border-box;
  color: #707070;
  font-size:13px;
  cursor: pointer;
}
.tablist li.on{
  background: #f9f9f9;
  border-top: 5px solid #eb7a3f;
  box-shadow: 1px 0 2px 0 rgba(0, 0, 0, 0.16);
  color: #5c5e60;
  font-weight: 800;
  z-index: 2;
}
.tablist li:last-child{
  border-right: 1px solid #bbb;
}
.table-wrapper {
  overflow-x: visible;
  overflow-y: hidden;
  border: 0;
  padding: 8px;
  box-shadow: none;
  height: calc(100% - 37px);
  box-sizing: border-box;
  box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #b1b1b169;
  border-top: 0;
  background: #fbfbfb;
}
.mainTable {
  min-width: 1200px;
}
.mainTable td {color: #898989;font-size: 13px;}
.inout{
  color: #600e89;
}
.total td{
  background: #efefef;
}
.rightline {
  border-right: 1px solid #cacaca;
}
.list{
  padding: 0 10px;
}
.list li{
  position: relative;
  cursor: pointer;
  font-size: 14px;
  color: #5c5e60;
  letter-spacing: -1px;
}
.list li > span {
   position: absolute;
   left: -18px;
   top: 14px;
   font-size: 16px;
   width: 14px;
   height: 14px;
   border-radius: 2px;
   background-color: #696969;
   color: #fff;
   display: flex;
   align-items: center;
   justify-content: center;
}
.list li > span.moreOn {
  /*transform: rotate(90deg);*/
}
.list li > span::before {content: '+'; padding-right: 1px;}
.list li > span.moreOn::before {content: '—';}
.list li > span.none::before {content: '•';}
.list li > div {
  padding: 10px 0;
  display: flex;
  gap: 3px;
  align-items: center;
}
.list li div.active {
   background-color: rgba(21, 228, 28, 0.26);
   padding: 3px;
}
.list span{
  color: #44b4ab;
}
.list em {
  width: 38px;
  height: 20px;
  color: #fff;
  font-size: 10px;
  border-radius: 2px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tab-head {
  display: flex;
  gap: 15px;
  font-size: 11px;
  align-items: center;
  font-weight: 400;
}
.tab-user {
  display: flex;
  gap: 5px;
  align-items: center;
}
.tab-user em {
  width: 38px;
  height: 20px;
  color: #fff;
  font-size: 10px;
  border-radius: 2px;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d505c; /*임의설정*/
}
.gametype.casino {color: #e50000;}
.gametype.slot {color: #48857b;}
.gametype.sport {color: #616161;}
.gametype.mini {color: #ff7e00;}
.tab-rate {
  display: flex;
  gap: 5px;
  align-items: center;
}
.tab-rate li{
  display: flex;
  gap: 5px;
  align-items: center;
}
.tab-rate li span:nth-child(2) {
  background-color: #e0e0e0;
  padding: 4px 5px;
}
.morebtn {
  position:relative;
}
/* .morebtn::before{
  content: "▶";
  position: absolute;
  left: -17px;
  top: 12px;
}
.morebtn.on::before {
  transform: rotate(90deg);
  color:#0a7b0e;
}
.moreOn::before{
  transform: rotate(90deg);
  color:#db8806;
} */
.mainTable td {height: 29px;}
.more{
  padding-left: 23px;
  position: relative;
}
/* .more::before {
  content: "";
  position: absolute;
  height: calc(100% - 30px);
  width: 1px;
  left: 13px;
  top: 16px;
  background: #eee;
} */
.more .more{
  padding-left: 23px;
}
.PTN_1 em, .PTN_1.boxs {background: #2b476e;}
.PTN_2 em, .PTN_2.boxs {background: #363b72;}
.PTN_3 em, .PTN_3.boxs {background: #1d505c;}
.PTN_4 em, .PTN_4.boxs {background: #af2a2e;}
.PTN_5 em, .PTN_5.boxs {background: #d98f00;}
.NORMAL em, .NORMAL.boxs {background: #df663a;}
.nick {font-weight: 800;}
.PTN_1 .nick {color: #2b476e;}
.PTN_2 .nick {color: #363b72;}
.PTN_3 .nick {color: #1d505c;}
.PTN_4 .nick {color: #af2a2e;}
.PTN_5 .nick {color: #d98f00;}
.NORMAL .nick {color: #df663a;}
</style>
